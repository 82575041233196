<script setup lang="ts">

import TheAccordionArrows from '@/Components/the-accordion/Components/TheAccordionArrows.vue'
import ToggleElement from '@/Components/the-accordion/Components/ToggleElement.vue'

type Props = {
    isOpen: boolean,
    showArrow?: boolean
}

withDefaults(defineProps<Props>(), {
    showArrow: true
})

const emit = defineEmits(['handleIsOpen'])

const toggle = (): void => {
    emit('handleIsOpen')
}

</script>

<template>
    <div
        class="toggle-section"

        @click="toggle"
    >
        <slot name="title" />

        <TheAccordionArrows v-if="showArrow" :open="isOpen" />
    </div>

    <ToggleElement :show="isOpen">
        <slot name="section" />
    </ToggleElement>
</template>

<style scoped lang="scss">
.toggle-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
</style>